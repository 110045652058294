<template>
  <main class="main orgs">
    <div class="container">
      <div ref="main_container" class="main__inner">
        <section class="section">
          <h2>Деятельность</h2>
          <div class="filter__form-change-wrap">
            <form
              @submit.prevent="filter"
              ref="filterForm"
              class="filter__form filter__form-change"
              autocomplete="off"
            >
              <div class="search filter search__long">
                <div class="search__input">
                  <label for="docs_search" class="search__label"> Название деятельности </label>
                  <div class="search__input__wrap">
                    <input v-model="form.title.value" id="docs_search" type="text" maxlength="255" />
                    <button v-if="form.title.value" type="button" @click="resetSearchInput">
                      <CloseIcon />
                    </button>
                  </div>
                </div>
                <div class="filter__item">
                  <label>Подразделение администрации</label>
                  <Multiselect
                    track-by="id"
                    label="title"
                    v-model="form.department_id.value"
                    :options="departments"
                    placeholder="Все подразделения"
                    selectLabel="Выбрать ↵"
                    deselectLabel="Удалить ↵"
                    selectedLabel="Выбрано"
                    :searchable="true"
                    :allow-empty="true"
                  >
                    <span slot="noOptions">Список пуст</span>
                    <span slot="noResult">Ничего не найдено.</span>
                  </Multiselect>
                </div>
                <div class="search__btn search__btn--with-clear">
                  <a @click.prevent="resetFilterForm" href="#" class="filter__reset"> Сбросить фильтр </a>
                  <button type="submit" class="btn-blue_dark" :disabled="loadingFilter">
                    <LoadingIndicator v-if="loadingFilter" title="Загрузка" />
                    <template v-else>Найти</template>
                  </button>
                </div>
              </div>
            </form>
            <a
              @click.prevent="changeFilterForm"
              class="filter__change-btn"
              :class="{ hide__btn: isFilterVisible }"
              href="#"
            >
              <span v-if="isFilterVisible">Скрыть фильтр</span>
              <span v-else>Поиск и фильтр</span>
              <ArrowDownIcon />
            </a>
          </div>
        </section>
        <section v-show="!isFilter" class="section section__la-btns section__divider">
          <ul class="la-btns__list">
            <li v-for="(item, index) in allCatalogs" :key="index" ref="item">
              <a
                v-if="item.title"
                @click.prevent="setActive(item.id)"
                :class="{ active: isActive(item.id) }"
                class="la__btn"
                href="#"
              >
                <i class="la__btn-icon icon" v-html="item.icon"> </i>
                <span class="la__btn-info">
                  <span class="la__btn-title">{{ item.title }}</span>
                  <span class="la__btn-description">{{ item.description }}</span>
                </span>
              </a>
            </li>
          </ul>
        </section>
        <section
          v-show="form.catalog_id.value || isFilter"
          ref="list"
          class="section section__la"
          :class="{ 'section__la--filter': isFilter }"
        >
          <LoadingIndicator v-if="loading || loadingFilter" title="Загрузка" />
          <div class="la__list" v-else-if="activities && activities.data && activities.data.length">
            <div v-for="(item, index) in activities.data" :key="index" class="la__item">
              <div class="la__title" v-if="item.title">
                <router-link
                  :to="{
                    name: 'messages_item',
                    params: { id: item.id, url: item.link },
                  }"
                >
                  {{ item.title }}
                </router-link>
              </div>
              <div class="la__subtitle" v-if="item.department && item.department.title">
                <router-link
                  :to="{
                    name: 'localOrganization',
                    params: { id: item.department.id },
                  }"
                  v-if="isInstitution(item.department)"
                >
                  {{ item.department.title }}
                </router-link>
                <a v-else :href="(item.department.url + '/activity') | url" target="_blank">
                  {{ item.department.title }},
                </a>
                <time :datetime="item.date_publication | robotDate">
                  {{ item.date_publication | formatDate }}
                </time>
              </div>
            </div>
          </div>
          <p v-else-if="!$store.state.filterData" class="_item__text">
            Информация о деятельности отсутствует
          </p>
          <p v-else-if="$store.state.filterData.get('title')" class="_item__text">
            По запросу "{{ $store.state.filterData.get("title") }}" ничего не найдено
          </p>
          <p v-else class="_item__text">По вашему запросу ничего не найдено</p>
          <PaginationComponent
            v-if="page !== null && total !== null && total > maxOnPage"
            :total="Math.ceil(total / maxOnPage)"
            @change="paginate"
            :page="page"
          />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import Multiselect from "vue-multiselect";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import PaginationComponent from "@/components/Pagination.vue";
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";

export default {
  name: "LocalActivities",
  async asyncData({ store, route }) {
    await store.dispatch("POST_ACTIVITIES_FILTER", {
      data: {
        catalog_id: parseInt(route.query.catalog_id) || undefined,
      },
    });
  },
  data() {
    return {
      blocksInRow: 3,
      page: 1,
      loading: false,
      loadingFilter: false,
      isFilterVisible: false,
      isFilter: false,
      form: {
        title: {
          value: null,
          message: null,
        },
        department_id: {
          value: null,
          message: null,
        },
        // catalog_id равен именно id, а не всему объекту, как остальные ..._id
        catalog_id: {
          value: null,
          message: null,
        },
      },
    };
  },
  mounted() {
    if (window.innerWidth <= 820) {
      this.blocksInRow = 1;
    } else if (window.innerWidth <= 1150) {
      this.blocksInRow = 2;
    } else {
      this.blocksInRow = 3;
    }
    let params = this.$route.query;
    if (params.catalog_id) {
      this.setActive(parseInt(params.catalog_id));
    }
  },
  computed: {
    activities() {
      return this.$store.state.activity_page?.activities;
    },
    total() {
      return this.$store.state.activity_page?.activities?.total;
    },
    maxOnPage() {
      return this.$store.state.activity_page?.activities?.per_page;
    },
    allCatalogs() {
      let catalogs = this.$store.state.activity_page
        ? [...this.$store.state.activity_page?.all_catalogs]
        : [];
      return catalogs.sort((a, b) => {
        if (a.position < b.position) {
          return -1;
        }
        if (a.position > b.position) {
          return 1;
        }
        return 0;
      });
    },
    departments() {
      return this.$store.state.activity_page?.departments || [];
    },
  },
  methods: {
    isInstitution(item) {
      return (item.type && item.type.code === this.$store.state.type.CODE_INSTITUTIONS) || !item.url;
    },
    changeFilterForm() {
      let filter = this.$refs.filterForm;
      filter.style.display = this.isFilterVisible ? "none" : "flex";
      this.isFilterVisible = !this.isFilterVisible;
    },
    isActive(id) {
      return this.form.catalog_id.value === id;
    },
    setActive(id) {
      // находим индекс элемента, чтобы знать куда вставлять список
      let index = this.allCatalogs.findIndex((item) => item.id === id);
      if (index !== -1) {
        let parent = this.$refs.item[0].parentNode;
        // берем элемент перед которым нужно вставить выпадающий список
        // если нажали на 0й элемент, то перед 3м, если на 5й то перед 6м (отчет с 0)
        let item = this.$refs.item[this.multipleOf(index + 1, this.blocksInRow)];
        parent.insertBefore(this.$refs.list, item);
      }
      if (id === this.form.catalog_id.value) {
        this.form.catalog_id.value = null;
        this.$store.state.activity_page.activities = [];
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("catalog_id")) {
          urlParams.delete("catalog_id");
          history.replaceState(null, "", location.pathname + urlParams);
        }
      } else {
        this.form.catalog_id.value = id;
        this.loading = true;
        const fd = new FormData();
        fd.append("catalog_id", this.form.catalog_id.value);
        this.$store.state.filterData = fd;
        this.setUrlParams({ catalog_id: this.form.catalog_id.value });
        this.load();
      }
    },
    // возвращает следующее кратное multiple число
    multipleOf(num, multiple) {
      return Math.floor((num + multiple - 1) / multiple) * multiple;
    },
    scrollTo(refName) {
      window.scrollTo({ top: this.$refs[refName].offsetTop - 100, behavior: "smooth" });
    },
    setUrlParams(params) {
      const urlParams = new URLSearchParams();
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          urlParams.set(key, JSON.stringify(params[key]));
        }
      });
      history.pushState({}, null, location.origin + location.pathname + "?" + urlParams.toString());
    },
    paginate(e) {
      this.page = e;
      let pageUrl = this.activities.first_page_url.slice(0, -1) + this.page;
      this.load(pageUrl);
    },
    /**
     * Фильтр
     * @returns {Promise<void>}
     */
    async filter() {
      if (!this.loadingFilter) {
        this.loadingFilter = true;
        this.page = 1;
        const fd = new FormData();
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        Object.keys(this.form).forEach((key) => {
          if (key === "department_id") {
            if (this.form[key].value) fd.append(key, this.form[key].value.id);
          } else if (key !== "catalog_id") {
            if (this.form[key].value !== null) fd.append(key, this.form[key].value);
          }
        });
        this.$store.state.filterData = fd;
        this.isFilter = true;
        this.$refs.main_container.appendChild(this.$refs.list);
        await this.load();
      }
    },
    async load(url) {
      await this.$store
        .dispatch("POST_ACTIVITIES_FILTER", {
          url: url,
          data: this.$store.state.filterData || {},
        })
        .then((response) => {
          this.loading = false;
          this.loadingFilter = false;
          this.scrollTo("list");
        })
        .catch((response) => {
          this.loading = false;
          this.loadingFilter = false;
          console.error(response.data);
        });
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.isFilter = false;
      this.$store.state.filterData = null;
      this.$store.state.filterResponse = null;
      this.$store.state.activity_page.activities = [];
      this.page = 1;
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
    },
  },
  components: {
    PaginationComponent,
    Multiselect,
    LoadingIndicator,
    CloseIcon,
    ArrowDownIcon,
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
    };
  },
};
</script>

<style lang="stylus">
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import "~@/styles/mixins/textcut.styl"

// la - local activity
.section__la-btns {
  padding-top 0
}

.section__la {
  width 100%
  padding-top 0
  //display flex
  //flex-direction column
  //align-items center

  &:not(&--filter) {
    background var(--color_aqua_light)
    padding 8px 32px 16px
    border-radius 8px
    +below(520px) {
      padding 8px 16px 16px
    }
  }
}

.section__filter-la {
  padding-top 24px
  padding-bottom 32px
}

.la {
  &-btns__list {
    display flex
    flex-wrap wrap
    gap 32px
    +below(520px) {
      gap 16px
    }

    > li {
      width calc(33.3333% - 22px)
      +below(1150px) {
        width calc(50% - 16px)
      }
      +below(820px) {
        width 100%
      }
    }
  }

  &__btn {
    display flex
    align-items flex-start
    gap 24px
    width 100%
    height 100%
    padding 24px 30px
    border-radius var(--radius)
    background var(--color_gray_light)
    word-break break-word
    +below(520px) {
      padding 20px 16px
    }

    &:hover {
      background: var(--color_blue_o05)
    }

    svg path {
      fill var(--color_blue)
    }

    &.active {
      background var(--color_blue)
      color var(--color_white)

      svg path {
        fill var(--color_white)
      }
    }

    .icon {
      flex-shrink 0
      width 32px
      height: 32px
      +below(520px) {
        width 28px
        height 28px
      }
    }

    &-info {
      display flex
      flex-direction column
      gap 12px
    }

    &-title {
      textcut(3)
      font-weight: 500;
      font-size: 1.125em
      line-height: 1.33
      word-break break-word
    }

    &-description {
      textcut(3)
      opacity 0.72
    }
  }

  &__list {
    width 100%
  }

  &__item {
    display flex
    flex-direction column
    padding 12px 0
    gap 8px

    &:not(:last-child) {
      border-bottom 1px solid var(--color_gray_divider)
    }
  }

  &__subtitle {
    > * {
      textcut(2)
      color var(--color_gray_dark)
      display inline
      word-break: break-word
    }
  }

  &__title {
    a {
      textcut(3)
      font-weight: 500;
      font-size: 1.125em;
      line-height: 24px;
      color var(--color_blue)
      word-break: break-word
      +below(768px) {
        font-size: 1em
      }
    }
  }
}
</style>
